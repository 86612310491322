import React from 'react';
import { Box,  Typography, useTheme, useMediaQuery } from '@mui/material';
import { shades } from 'theme';


const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const people = 'https://entrepreneuriallegalpartner.com/assets/enterpreneurs.jpg';
  return (
    <Box
      sx={{
        backgroundImage: `url(${people})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'white',
        padding: '2rem',
        textAlign: 'center',
        minHeight: isMobile ? '50vh' : '100vh', // Adjust the minimum height as needed
        position: 'relative',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
          padding: '2rem',          
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant='h2' sx={{ mb: 2 }}>
          Empowering Entrepreneurs
        </Typography>
        <Typography variant='h4' sx={{ color: shades.lighgreen }}>
          Tailored and Automated Legal Support
        </Typography>
      </Box>
    </Box>
  );
};

export default Hero;
