import React from 'react';
import { Box, Typography, useTheme, useMediaQuery, Grid, Button } from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { shades } from 'theme';

const Services = () => {
  const IP = 'https://entrepreneuriallegalpartner.com/assets/IP.jpg';
  const registration = 'https://entrepreneuriallegalpartner.com/assets/businessRegistration.jpg';
  const consultation = 'https://entrepreneuriallegalpartner.com/assets/legalConsulation.jpg';
  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  
  const services = [
    {
      image: IP,
      service: 'IP Management',
      description: 'We help businesses protect their intellectual property by offering comprehensive IP management services, including trademark registration, patent filing, and legal advice on IP rights.',
    },
    {
      image: registration,
      service: 'Business Registration',
      description: 'Our team assists in the seamless registration of businesses, ensuring all legal requirements are met, and helping you navigate the complexities of business law.',
    },
    {
      image: consultation,
      service: 'Legal Consultation',
      description: 'We provide expert legal consultation tailored to the needs of startups and established businesses, helping you navigate legal challenges with confidence.',
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: shades.white[500],
        padding: { xs: '2rem', sm: '2rem', md: '2rem', lg: '3rem 10rem' },
        textAlign: 'center',
      }}
    >
      <Typography variant="h4" sx={{ color: shades.darkgreen, mb: 3 }}>
        Our Services
      </Typography>
      <Typography variant="body1" sx={{ color: shades.indigo[500], mb: 4 }}>
        These are some of the services we offer to help your business succeed.
      </Typography>
      <Grid container spacing={3}>
        {services.map((service, index) => (
          <Grid 
            item 
            xs={12} 
            sm={4} 
            md={4} 
            lg={4} 
            key={index}
          >
            <Box
              sx={{
                backgroundColor: shades.black[500],
                color: 'white',
                borderRadius: '10px',
                overflow: 'hidden',
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                margin: '0 auto',
                minHeight: { xs: '200px', md: '300px' },
              }}
            >
              <img
                src={service.image}
                alt={service.service}
                style={{
                  width: '90%',
                  height: 'auto',
                  objectFit: 'cover',  // Ensures image covers the box proportionally
                  margin: '1rem auto',  // Centers the image with margin around it
                  borderRadius: '10px', // Keeps border-radius consistent
                }}
              />
              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                {service.service}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2, mr:2, ml:2 }}>
                {service.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      {/* Wrap the Button with Link */}
      <Link to="/Services" style={{ textDecoration: 'none' }}>
        <Button 
          sx={{
            marginTop: '2rem',
            backgroundColor: shades.indigo[500],
            color: 'white',
            padding: isMobile ? '0.5rem 1rem' : '1rem 2rem',
            fontSize: isMobile ? '1rem' : '1.25rem',
            '&:hover': {
              backgroundColor: shades.indigo[600],
            }
          }}
        >
          <Typography textTransform='capitalize'>View All Our Services</Typography>
        </Button>
      </Link>
    </Box>
  );
};

export default Services;
