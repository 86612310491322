import React from 'react';
import {
  Container,  
  Button,
  Typography,  
  Box,
  Paper,  
  AppBar,
  Toolbar
} from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate, useLocation } from 'react-router-dom';

const RecommendationPage = () => {
    const location = useLocation();
    const { recommendation, country } = location.state || { recommendation: '', country: '' };
  
    const navigate = useNavigate();
  
    const handleTryAnother = () => {
      navigate('/business-structure-recommender'); // Navigate back to the form page
    };
  
    return (
      
        <Container maxWidth="md" style={{ padding: '20px' , marginTop:"5rem"}}>
          <AppBar position="static" color="primary">
            <Toolbar>
              <BusinessIcon style={{ marginRight: '10px' }} />
              <Typography variant="h6">Recommendation</Typography>
            </Toolbar>
          </AppBar>
  
          <Box mt={4} mb={2}>
            <Typography variant="h4" gutterBottom style={{ marginBottom: '20px', textAlign: 'center' }}>
              Your Recommended Business Structure
            </Typography>
          </Box>
  
          <Paper elevation={3} style={{ padding: '20px', height: '400px'}}>
            <Typography variant="h6" gutterBottom>
              Based on the information provided, the recommended business structure for your country {country} is:
            </Typography>
            <Typography variant="body1" paragraph>
              {recommendation}
            </Typography>
            <Typography
              variant="body1"
              style={{ marginTop: '20px' }}
            >
              For more information and assistance with registration in {country}, 
              contact ELP. Our team is here to help you navigate the process 
              and ensure compliance with local regulations.
            </Typography>
            <Box mt={3} style={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleTryAnother}
              >
                Try Another Entry
              </Button>
            </Box>
          </Paper>
        </Container>
      
    );
  };

  export default RecommendationPage;