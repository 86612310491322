import React from 'react'
import Hero from './Hero'
import Main from './Main'

const Services = () => {
  return (
    <>
      <Hero/>
      <Main/>
    </>
  )
}

export default Services