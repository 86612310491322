import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import { shades } from 'theme'

const AboutUs = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
    const text1 = 'We aim to bridge the gap between startups and local and international policies. Our end goal is to ensure individual entrepreneurs, startups, and businesses are acquiring the necessary legal protections, easily integrating regulatory policies to evade penalties, and having a seamless legal process during registration, development, and international expansions.';
    const text2 = 'We do this through a tailored approach to jurisdictional tailored services and a legal toolkit that automates processes.';
    const text3 = 'We pride in being a reliable partner to entrepreneurs.';
  return (
    <Box
      sx={{
        backgroundColor: shades.black[500],
        color: 'white',
        padding: isMobile ? '2rem' : '10rem',
        textAlign: 'center',
        alignItems:'center',
        alignContent: 'center',
        minHeight: '50vh',
      }}
    > 
        <Typography variant='h4' color={shades.lighgreen} sx={{ mb: '1rem' }}>
            About Us
        </Typography>
        <Typography sx={{ mb: '1rem' }}>
            {text1}
        </Typography>
        <Typography sx={{ mb: '1rem' }}>
            {text2}
        </Typography>
        <Typography sx={{ mb: '1rem' }}>
            {text3}
        </Typography>       
        
    </Box>
  )
}

export default AboutUs