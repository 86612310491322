import React from 'react';
import { Box, Grid, Typography, TextField, Button } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { shades } from 'theme';

const Main = () => {
  return (
    <Box
      sx={{
        backgroundColor: shades.indigo[100],
        padding: { xs: '1rem', sm: '2rem', md: '2rem', lg: '3rem 10rem 3rem 10rem' },
        textAlign: 'center',
      }}
    >
      <Grid container spacing={4}>
        <Grid 
          item 
          xs={12} 
          sm={12} 
          md={6} 
          lg={4}
        >
          <Box
            sx={{
              backgroundColor: shades.darkgreen,
              color: shades.white[500],
              borderRadius: '5px',
              padding: '1rem',
              height: '100%',
              textAlign: 'left', // Ensure left alignment for the content
            }}
          >
            <Typography variant="h6" textTransform='capitalize'>
              Let's Talk
            </Typography>
            <Typography variant="body1" gutterBottom>
              Speak With Expert Legal Advisors
            </Typography>
            
            <Box display="flex" alignItems="flex-start" mb={2} mt={2}>
              <EmailIcon sx={{ marginRight: '8px', fontSize: 40 }} />
              <Box>
                <Typography>Email:</Typography>
                <Typography> entr.legal.partner@gmail.com</Typography>
              </Box>
            </Box>
            
            <Box display="flex" alignItems="flex-start" mb={2} mt={2}>
              <PhoneIcon sx={{ marginRight: '8px', fontSize: 40 }} />
              <Box>
                <Typography>Phone:</Typography>
                <Typography>123-456-7890</Typography>
              </Box>
            </Box>
            
            <Box display="flex" alignItems="flex-start" mt={2}>
              <LocationOnIcon sx={{ marginRight: '8px', fontSize: 40 }} />
              <Box>
                <Typography>Location:</Typography>
                <Typography>Nairobi, Kenya</Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid 
          item 
          xs={12} 
          sm={6} 
          md={6} 
          lg={8}
          sx={{ display: 'flex', justifyContent: 'center' }} 
          
        >
          <Box
            sx={{
              padding: '1rem',
              height: '100%',
              // Optional: Set a max width on larger screens
            }}
          >
            <Typography variant="h6" gutterBottom>
              Get In Touch
            </Typography>
            <Typography variant="body1" gutterBottom>
              Fill The Form Below
            </Typography>
            
            <form>
              <TextField
                fullWidth
                label="First Name"
                margin="normal"
                variant="outlined"
                required
              />
              <TextField
                fullWidth
                label="Email Address"
                margin="normal"
                variant="outlined"
                type="email"
                required
              />
              <TextField
                fullWidth
                label="Phone Number"
                margin="normal"
                variant="outlined"
                type="tel"
                required
              />
              <TextField
                fullWidth
                label="Company"
                margin="normal"
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Message"
                margin="normal"
                variant="outlined"
                multiline
                rows={4}
                required
              />
              <Button
                variant="contained"
                sx={{ backgroundColor: shades.darkgreen, color: shades.white[500], marginTop: '1rem' }}
                fullWidth
                type="submit"
              >
               <Typography textTransform='capitalize'>Send Message</Typography> 
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
