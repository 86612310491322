import React from 'react'
import Hero from './Hero'

const Jobs = () => {
  return (
    <>
      <Hero/>
    </>
  )
}

export default Jobs