import React from 'react';
import { Box,  Typography, useTheme, useMediaQuery } from '@mui/material';


const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const about = 'https://entrepreneuriallegalpartner.com/assets/aboutUs.jpg';
  return (
    <Box
      sx={{
        backgroundImage: `url(${about})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        color: 'white',
        padding: '2rem',
        textAlign: 'center',
        minHeight: isMobile ? '30vh' : '50vh', // Adjust the minimum height as needed
        position: 'relative',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent overlay
          padding: '2rem',
          borderRadius: '15px',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant='h2' sx={{ mb: 2 , mt:5}}>
          Jobs
        </Typography>        
      </Box>
    </Box>
  );
};

export default Hero;
