import React, { useState } from 'react';
import { Container, Box, Typography, Button, AppBar, Toolbar, Grid, Paper, Chip, TextField, Snackbar } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import ToolIcon from '@mui/icons-material/Build';
import { Link } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';

const tools = [
  {
    name: 'Business Structure Recommender',
    description: 'Get recommendations on the best structure for your business based on specific criteria.',
    route: '/business-structure-recommender',
    status: 'available', // Status can be 'available' or 'coming soon'
  },
  {
    name: 'Automatic Legal Documents Generator',
    description: 'Automatically generate documents for legal purposes based on your jurisdictional provisions.',
    route: '/other-tool',
    status: 'coming soon',
  },
  {
    name: 'AI-E Optimizer',
    description: 'Optimize AI and edge infrastructure with real-time, data-driven recommendations for sustainable development.',
    route: '/AI-E-Optimizer',
    status: 'available',
  },
];

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const ToolsPage = ({ onNavigate }) => {
  const [showForm, setShowForm] = useState(false);
  const [formValues, setFormValues] = useState({
    email: '',
    fullNames: '',
    portfolioLink: '',
    linkedinLink: '',
    resumeLink: '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleFormChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission (e.g., API call)
    console.log(formValues);
    setShowForm(false);
    window.scrollTo(0, 0);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Container maxWidth="lg" style={{ padding: '20px', marginTop: '5rem' }}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
            <Box display="flex" flexDirection="row" alignItems="center">
              <ToolIcon style={{ marginRight: '10px' }} />
              <Typography variant="h6">Tools Dashboard</Typography>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowForm(!showForm)}
            >
              {showForm ? 'Close Form' : 'Join ELPlab'}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Box mt={4} mb={2}>
        <Typography variant="h4" gutterBottom style={{ textAlign: 'center' }}>
          Available Tools
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {tools.map((tool, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              elevation={3}
              style={{
                padding: '20px',
                textAlign: 'center',
                height: '500px', // Ensures uniform size
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <BusinessIcon color={tool.status === 'available' ? 'primary' : 'secondary'} style={{ fontSize: 50 }} />
              <Typography variant="h6" gutterBottom>
                {tool.name}
              </Typography>
              <Typography variant="body1" paragraph>
                {tool.description}
              </Typography>
              <Chip
                label={tool.status === 'available' ? 'Available' : 'Coming Soon'}
                color={tool.status === 'available' ? 'primary' : 'secondary'}
                style={{ marginBottom: '15px' }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => onNavigate(tool.route)}
                disabled={tool.status === 'coming soon'}
              >
                {tool.status === 'available' ? 'Open Tool' : 'Coming Soon'}
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {showForm && (
        <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
          <Typography variant="h4" gutterBottom align="center">
            Join ELPlab
          </Typography>
          <form onSubmit={handleFormSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
              <TextField
                label="Email"
                variant="outlined"
                name="email"
                value={formValues.email}
                onChange={handleFormChange}
                fullWidth
                required
              />
              <TextField
                label="Full Names"
                variant="outlined"
                name="fullNames"
                value={formValues.fullNames}
                onChange={handleFormChange}
                fullWidth
                required
              />
              <TextField
                label="Portfolio Link"
                variant="outlined"
                name="portfolioLink"
                value={formValues.portfolioLink}
                onChange={handleFormChange}
                fullWidth
              />
              <TextField
                label="LinkedIn Link"
                variant="outlined"
                name="linkedinLink"
                value={formValues.linkedinLink}
                onChange={handleFormChange}
                fullWidth
              />
              <TextField
                label="Resume Link"
                variant="outlined"
                name="resumeLink"
                value={formValues.resumeLink}
                onChange={handleFormChange}
                fullWidth
              />
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </Paper>
      )}

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity="success">
          Form submitted successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ToolsPage;
