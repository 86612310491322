import React from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing
import { shades } from 'theme';

const Main = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const IP = 'https://entrepreneuriallegalpartner.com/assets/IP.jpg';
  const registration = 'https://entrepreneuriallegalpartner.com/assets/businessRegistration.jpg';
  const consultation = 'https://entrepreneuriallegalpartner.com/assets/legalConsulation.jpg';
  const legalDocuments = 'https://entrepreneuriallegalpartner.com/assets/legalDocuments.jpg';
  const regulation = 'https://entrepreneuriallegalpartner.com/assets/regulation.jpg';
  const international = 'https://entrepreneuriallegalpartner.com/assets/international.jpg';
  
  const services = [
    {
      image: IP,
      service: 'IP Management',
      description: 'We help businesses protect their intellectual property by offering comprehensive IP management services, including trademark registration, patent filing, and legal advice on IP rights.',
    },
    {
      image: registration,
      service: 'Business Registration',
      description: 'Our team assists in the seamless registration of businesses, ensuring all legal requirements are met, and helping you navigate the complexities of business law.',
    },
    {
      image: consultation,
      service: 'Consultation',
      description: 'We provide expert legal consultation tailored to the needs of startups and established businesses, helping you navigate legal challenges with confidence.',
    },
    {
      image: legalDocuments,
      service: 'Legal Documents Drafting',
      description: 'Our legal experts assist in drafting essential legal documents, ensuring all your agreements are compliant and legally binding.',
    },
    {
      image: regulation,
      service: 'Regulatory Compliance',
      description: 'We help businesses adhere to all relevant regulations, ensuring your operations are compliant with local and international laws.',
    },
    {
      image: international,
      service: 'International Legal Services',
      description: 'We offer legal services to businesses operating internationally, navigating the complexities of global law to protect your interests.',
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: shades.white[500],
        padding: { xs: '2rem', sm: '2rem', md: '2rem', lg: '3rem 10rem' },
        textAlign: 'center',
      }}
    >
      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid 
            item 
            xs={12} 
            sm={6} 
            md={6} 
            lg={4}
            key={index}
          >
            <Box
              sx={{
                border: `1px solid ${shades.darkgreen}`,
                padding: '1rem',
                height: '100%',
                margin: '0 auto',
                boxShadow: `0 4px 8px rgba(0, 0, 0, 0.2)`,
                borderRadius: '8px',
                transition: 'transform 0.3s, boxShadow 0.3s',
                '&:hover': {
                  boxShadow: `0 8px 16px rgba(0, 0, 0, 0.3)`,
                  transform: 'scale(1.02)',
                },
              }}
            >
              <img
                src={service.image}
                alt={service.service}
                style={{
                  width: '100%',
                  height: '200px',
                  objectFit: 'cover',
                  marginBottom: '1rem',
                }}
              />
              <Typography variant="h6" color={shades.black} gutterBottom>
                {service.service}
              </Typography>
              <Divider sx={{ backgroundColor: shades.darkgreen, height: '1px', marginY: '1rem' }} />
              <Typography variant="body2" color={shades.black}>
                {service.description}
              </Typography>
              <Button 
                sx={{ backgroundColor: shades.darkgreen, color: shades.white[500], marginTop: 2 }}
                onClick={() => navigate(service.service === 'Consultation' ? '/Book-Consultation' : '/Get-A-Quote')} // Route based on service
              >
                <Typography textTransform='capitalize'>{service.service === 'Consultation' ? 'Book' : 'Get a Quote'}</Typography>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Main;
