import { Box, Typography } from '@mui/material';
import Heatmap from 'components/website/Maps/HeatMap'; // Ensure the correct path to the Heatmap component
import React from 'react';

function Countries() {
  return (
    <Box 
      display="flex"
      p={{ md: '2rem', lg: '3rem 10rem' }}
      flexDirection={{ xs: 'column', md: 'row' }}
      flexGrow={2}
    >
      <Box flex={1.5} display="flex" justifyContent="center">
        <Heatmap />
      </Box>
      <Box 
        flex={0.5} 
        display="flex" 
        flexDirection="column"
        justifyContent="center"
        alignItems={{ xs: 'center', md: 'flex-start' }}
        padding={{ xs: '1rem', md: '2rem' }}
      >
        <Typography variant="h4">
        Where Our Clients Are
        </Typography>
        <Typography>
          These are some of the countries we work with.
        </Typography>
      </Box>
    </Box>
  );
}

export default Countries;
