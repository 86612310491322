import { Box } from '@mui/material';
import React from 'react';
import Navbar from './Navbar';


const Header = () => {
  return (
    <Box >
      <Navbar />
    </Box>
  );
};

export default Header;
