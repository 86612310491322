import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import Slider from 'react-slick';
import { shades } from 'theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Success = () => {
  const successStories = [
    {
      companyName: 'Energator',
      executiveName: 'John Doe, CEO',
      sentiment: 'ELP provided us with exceptional legal advice that helped us navigate complex energy regulations. Their guidance was crucial in securing our latest partnership.',
    },
    {
      companyName: 'Skripton',
      executiveName: 'Jane Smith, CTO',
      sentiment: 'Thanks to ELP, we were able to protect our intellectual property and secure key patents that have driven our innovation forward.',
    },
    {
      companyName: 'Travelo',
      executiveName: 'James Lee, Founder',
      sentiment: 'ELP’s expertise in business registration and legal compliance was instrumental in helping us expand our operations internationally.',
    },
    {
      companyName: 'GazeIt',
      executiveName: 'Sarah Johnson, COO',
      sentiment: 'The legal consultation provided by ELP has been a game-changer for our startup. Their insights into tech law have saved us time and money.',
    },
    {
      companyName: 'GLMP.it',
      executiveName: 'Mike Brown, CFO',
      sentiment: 'With ELP’s support, we successfully navigated the legal challenges of launching our new app. Their team was there for us every step of the way.',
    },
    {
      companyName: 'MTVS.news',
      executiveName: 'Emily Davis, Editor-in-Chief',
      sentiment: 'ELP’s legal team helped us protect our brand and intellectual property as we launched our new digital platform. Their expertise is unmatched.',
    },
  ];

  const longtext = "Discover what our clients have to say about the impact of our legal services. Let their success stories inspire you to take the next step in your business journey.";

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };

  return (
    <Box
      sx={{
        backgroundColor: shades.black[500],
        padding: { md: '2rem ', lg: '5rem 10rem 3rem 10rem' },
        textAlign: 'center',
        display: 'flex',
        flexDirection: { xs: 'column', lg: 'row' },
        alignItems: 'center',
      }}
    >
      {/* Left */}
      <Box flex={1.5} alignContent='center' textAlign={{ xs: 'center', md: 'center', lg: 'left' }} mb={{ md: 3 }} mr={{ lg: 2 }}>
        <Typography variant='h4' color={shades.lighgreen} sx={{ mb: 2 }}>
          Client Success Stories
        </Typography>
        <Typography color={shades.white[500]} sx={{ mb: 2 }}>
          {longtext}
        </Typography>
      </Box>

      {/* Divider */}
      <Divider 
        sx={{ 
          borderColor: shades.white[500], 
          borderWidth: { xs: '1px', lg: '1px' }, 
          borderStyle: 'solid',
          height: { xs: '1px', lg: 'auto' }, // Horizontal on small screens, vertical on large
          width: { xs: '100%', lg: '1px' }, // Full width on small screens, thin vertical line on large
          alignSelf: 'stretch',
          maxHeight: { lg: 'calc(100vh - 4rem)' }, // Adjust max height to be shorter than the Box
          mb: { xs: 2, lg: 0 },
        }} 
      />

      {/* Right */}
      <Box flex={1.5} sx={{ maxWidth: '100%', overflow: 'hidden' }}>
        <Slider {...settings}>
          {successStories.map((story, index) => (
            <Box key={index} sx={{ padding: '1rem' }}>
              <Box
                sx={{                
                  borderRadius: '10px',
                  padding: '1.5rem',
                  minHeight: '200px',
                  maxWidth: '50rem',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1, color: shades.lighgreen }}>
                  {story.companyName}
                </Typography>
                <Typography variant="body2" sx={{ fontStyle: 'italic', mb: 2, color: shades.white[500] }}>
                  "{story.sentiment}"
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 'bold', color: shades.indigo[400] }}>
                  - {story.executiveName}
                </Typography>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default Success;
