import { createTheme } from '@mui/material/styles';

// Color tokens according to company palette
export const shades = {
  black: {
    100: "#cccccc",
    200: "#999999",
    300: "#666666",
    400: "#333333",
    500: "#000000",
    600: "#000000",
    700: "#000000",
    800: "#000000",
    900: "#000000"
  },
  white: {
    100: "#ffffff",
    200: "#ffffff",
    300: "#ffffff",
    400: "#ffffff",
    500: "#ffffff",
    600: "#cccccc",
    700: "#999999",
    800: "#666666",
    900: "#333333"
  },
  indigo: {
    100: "#d8e3dc",
    200: "#b1c7ba",
    300: "#8baa97",
    400: "#648e75",
    500: "#3d7252",
    600: "#315b42",
    700: "#254431",
    800: "#182e21",
    900: "#0c1710"
  },
  lighgreen: '#bada55',
  darkgreen: '#013A17',
};

export const theme = createTheme({
  palette: {
    primary: {
      main: shades.black[500],
      light: shades.black[100],
    },
    secondary: {
      main: shades.white[100],
      second: shades.lighgreen,
      third: shades.darkgreen,
    },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    fontSize: 18,
    fontWeight: 400,

    h1: {
      fontFamily: ["Open Sans, sans-serif"].join(","),
      fontSize: 48,
      fontWeight: 400,
      [`@media (min-width: 576px)`]: {
        fontSize: 56,
      },
      [`@media (min-width: 768px)`]: {
        fontSize: 64,
      },
      [`@media (min-width: 992px)`]: {
        fontSize: 72,
      },
      [`@media (min-width: 1200px)`]: {
        fontSize: 80,
      },
    },
    h2: {
      fontFamily: ["Open Sans, sans-serif"].join(","),
      fontSize: 40,
      fontWeight: 400,
      [`@media (min-width: 576px)`]: {
        fontSize: 44,
      },
      [`@media (min-width: 768px)`]: {
        fontSize: 48,
      },
      [`@media (min-width: 992px)`]: {
        fontSize: 52,
      },
      [`@media (min-width: 1200px)`]: {
        fontSize: 56,
      },
    },
    h3: {
      fontFamily: ["Open Sans, sans-serif"].join(","),
      fontSize: 32,
      fontWeight: 400,
      [`@media (min-width: 576px)`]: {
        fontSize: 36,
      },
      [`@media (min-width: 768px)`]: {
        fontSize: 40,
      },
      [`@media (min-width: 992px)`]: {
        fontSize: 44,
      },
      [`@media (min-width: 1200px)`]: {
        fontSize: 48,
      },
    },
    h4: {
      fontFamily: ["Open Sans, sans-serif"].join(","),
      fontSize: 28,
      fontWeight: 400,
      [`@media (min-width: 576px)`]: {
        fontSize: 32,
      },
      [`@media (min-width: 768px)`]: {
        fontSize: 36,
      },
      [`@media (min-width: 992px)`]: {
        fontSize: 40,
      },
      [`@media (min-width: 1200px)`]: {
        fontSize: 44,
      },
    },
    h5: {
      fontFamily: ["Open Sans, sans-serif"].join(","),
      fontSize: 24,
      fontWeight: 400,
      [`@media (min-width: 576px)`]: {
        fontSize: 28,
      },
      [`@media (min-width: 768px)`]: {
        fontSize: 32,
      },
      [`@media (min-width: 992px)`]: {
        fontSize: 36,
      },
      [`@media (min-width: 1200px)`]: {
        fontSize: 40,
      },
    },
    h6: {
      fontFamily: ["Open Sans, sans-serif"].join(","),
      fontSize: 20,
      fontWeight: 400,
      [`@media (min-width: 576px)`]: {
        fontSize: 24,
      },
      [`@media (min-width: 768px)`]: {
        fontSize: 28,
      },
      [`@media (min-width: 992px)`]: {
        fontSize: 32,
      },
      [`@media (min-width: 1200px)`]: {
        fontSize: 36,
      },
    },
  },
});
