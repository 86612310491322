import React from 'react';
import { Box } from '@mui/material';

const Map = () => {
  const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
  return (
    <Box 
      sx={{ 
        width: '100%', 
        height: '400px', 
        marginTop: '2rem',
        overflow: 'hidden'   
      }}
    >
      <iframe
        title="Google Map"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        loading="lazy"
        allowFullScreen
        src={`https://www.google.com/maps/embed/v1/place?q=Nairobi,+Kenya&key=${API_KEY}`}
      />
    </Box>
  );
}

export default Map;
