import React from 'react'
import Hero from './Hero'
//import Main from './Main'
import Services from './Services'
import Success from './Success'
import ContactMain from '../ContactUs/Main'
//import ContactMap from '../../../components/website/Maps/Map'
import Countries from './Countries'
import AboutUs from './AboutUs'
import Partners from './Patners'

const Home = () => {
  return (
    <>
      <Hero/>
      {/* <Main/> */}
      <AboutUs/>
      <Services/>
      <Countries/>
      <Success/>
      <ContactMain/>
      <Partners/>
      {/* <ContactMap/> */}
    </>
  )
}

export default Home