import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { shades } from '../../../theme.js';

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundColor: shades.darkgreen,
        color: 'white',
        padding: '2rem',
        textAlign: 'center',
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: '3rem' }}>
        ELP
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Typography variant="body1">entr.legal.partner@gmail.com
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <Typography variant="body1">Nairobi, Kenya</Typography>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4}>        
          <Typography variant="body1">123-456-7890</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
