import React, { useState} from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from 'scenes/website/Home';
import AboutUs from 'scenes/website/AboutUs';
import Services from 'scenes/website/Services';
import Blogs from 'scenes/website/Blogs';
import Jobs from 'scenes/website/Jobs';
import ContactUs from 'scenes/website/ContactUs';
import Footer from 'components/website/Footer';
import Header from 'components/website/Header';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import ChatIcon from '@mui/icons-material/Chat';
import { shades } from 'theme';
import ChatDialog from 'components/website/ChatDialog';
import Error404 from 'components/global/404';
import Error504 from 'components/global/504';
import BusinessStructureRecommender from 'scenes/website/BusinessStructure/BusinessStructureRecomender';
import RecommendationPage from 'scenes/website/BusinessStructure/Recommendation';
import LoadingScreen from 'components/website/Tools/LoadingScreen';
import ToolsPage from 'scenes/website/BusinessStructure/Tools';
import Optimizer from 'scenes/website/BusinessStructure/Optimizer';
import QuoteForm from 'components/website/Forms/QuoteForm';
import BookingForm from 'components/website/Forms/BookingForm';

function App() {
  const [openChat, setOpenChat] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const handleOpenChat = () => {
    setOpenChat(true);
  };

  const handleCloseChat = () => {
    setOpenChat(false);
  };

  const handleNavigation = (path) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      window.location.href = path;
    }, 5000); // Adjust the timeout duration as needed
  };

  // Check if the current route is the 404 or 504 error page
  const isErrorPage = location.pathname === '/404' || location.pathname === '/504';
  

  return (
    <>
      {/* Render Header and Footer only if not on an error page */}
      {!isErrorPage && <Header />}
      
      {loading ? (
        <LoadingScreen />
      ) : (
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/About-Us" element={<AboutUs />} />
          <Route path="/Services" element={<Services />} />
          <Route path="/Blogs" element={<Blogs />} />
          <Route path="/Jobs" element={<Jobs />} />
          <Route path="/Contact-Us" element={<ContactUs />} />
          <Route path="/404" element={<Error404 />} />
          <Route path="/504" element={<Error504 />} />
          <Route path="*" element={<Error404 />} />
          {/* Tools-related Routes */}
          <Route path="/Tools" element={<ToolsPage onNavigate={handleNavigation} />} />
          <Route path="/business-structure-recommender" element={<BusinessStructureRecommender />} />
          <Route path="/recommendation" element={<RecommendationPage />} />
          <Route path="/AI-E-Optimizer" element={<Optimizer />} />  
          {/* Forms routes  */}
          <Route path="/Get-A-Quote" element={<QuoteForm />} />
          <Route path="/Book-Consultation" element={<BookingForm />} />
        </Routes>
      )}
      
      {!isErrorPage && <Footer />}

      {/* Speed Dial for "Let's Chat" */}
      <SpeedDial
        ariaLabel="Let's Chat"
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          color: 'white', 
          '& .MuiSpeedDial-fab': {
            backgroundColor: shades.darkgreen,
            color: 'white',
            '&:hover': {
              backgroundColor: shades.darkgreen[700],
            },
          },
        }}
        icon={<SpeedDialIcon openIcon={<ChatIcon />} />}
        onClick={handleOpenChat} 
      />

      {/* Chat Dialog */}
      <ChatDialog open={openChat} handleClose={handleCloseChat} />
    </>
  );
}

export default App;
